<template>
  <div class="footer-container">

    <!-- Footer -->
    <div class="container footer-content" v-if="isShowFooter">
      <div class="md:flex flex-wrap justify-between gap-20 auto-cols-max space-y-6 sm:space-y-10 md:space-y-0">

        <template v-if="Array.isArray(footerMenu) && footerMenu.length > 0">
          <div class="sm:block hidden" v-for="i in footerMenu" :key="`ftm-${i.id}`" v-if="isDesktop">
            <ul role="list" class="text-left">
              <h3 class="text-base font-bold text-primary-500 mb-2 text-left tracking-wider uppercase"
                  v-if="getLocaleString(i.name)">
                {{ getLocaleString(i.name) }}
              </h3>

              <li v-for="item in i.children" :key="`ftmc-${item.id}`">
                <NuxtLinkLocale :to="getLocaleString(item.href, true)" class="text-base text-primary-500 hover:underline">
                  {{ getLocaleString(item.name) }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div class="sm:hidden block" v-else>
            <TemplatesFooterMobileFooter/>
          </div>
        </template>

        <!-- Newsletter -->
        <div class="">
          <h3 class="text-base font-bold text-primary-500 mb-2 text-left tracking-wider uppercase">
            {{ $t('footer.contact-us') }}
          </h3>

          <div class="space-y-4">
            <div>
              <a class="block text-xl font-medium hover:text-secondary" :href="`tel:${phoneNumber}`" v-if="phoneNumber">
                <LucidePhone class="h-4 w-4 inline-block mr-1.5"/>
                {{ phoneNumber }}</a>
              <a class="block text-xl font-medium hover:text-secondary" :href="`tel:${phoneNumber2}`" v-if="phoneNumber2">
                <LucidePhoneCall class="h-4 w-4 inline-block mr-1.5"/>
                {{ phoneNumber2 }}
              </a>
              <div class="text-base mt-2" v-if="supportEmail">
                <AtSymbolIcon class="h-4 w-4 inline-block mr-1.5"/>
                {{ supportEmail }}
              </div>
            </div>

            <div class="" v-if="contactConfig?.workingHoursIsActive || contactConfig?.workingHoursIsActive">
              <div class="font-medium">{{ $t('footer.working-hours') }}</div>
              <div v-if="contactConfig?.workingHoursIsActive">{{ workingDays }}</div>
              <div v-if="contactConfig?.workingHoursIsActive">{{ workingHours }}</div>
            </div>
          </div>
        </div>

        <div class="space-y-1 lg:space-y-4 col-span-2 col-start-4 flex flex-col items-end">

          <Newsletter />

          <div class="space-y-6">
            <div class="flex flex-wrap space-x-1 md:space-x-3"
                 v-if="footerConfig?.social_media_links && Array.isArray(footerConfig.social_media_links)">
              <a
                  :key="`nwm-${idx}`"
                  :href="getLocaleString(item.link)"
                  class="fill-primary-400 hover:opacity-80 rounded-full p-2"
                  v-for="(item,idx) in footerConfig.social_media_links"
              >
                <NuxtImg class="size-6 object-contain" :src="item.icon" :alt="getLocaleString(item.name)" />
              </a>
            </div>
          </div>

          <NuxtLinkLocale class="block" :href="etbis.url" target="_blank" v-if="etbis?.url && etbis?.image">
            <NuxtImg class="w-24" loading="lazy" :src="etbis.image"/>
          </NuxtLinkLocale>
        </div>
      </div>
    </div>

    <div class="container footer-html-area px-4 lg:px-0"
         v-if="getLocaleString(footerConfig?.legal_notice_text)"
         v-html="getLocaleString(footerConfig?.legal_notice_text)">
    </div>

    <!-- Copyright -->
    <Copyright/>
  </div>
</template>

<script setup>
import {PhoneIcon, AtSymbolIcon} from "@heroicons/vue/20/solid";

const {locale} = useI18n()

const {isDesktop} = useDevice()
const {isWholesaleUser} = await useCustomer()
const {isShowFooter} = useHelper()

const storeConfig = useStoreConfig()
const { footerMenu, footerConfig, contactConfig, etbis } = storeToRefs(storeConfig)

const phoneNumber = computed(() => contactConfig.value?.phone ? getLocaleString(contactConfig.value?.phone) : "")
const phoneNumber2 = computed(() => contactConfig.value?.phone_2 ? getLocaleString(contactConfig.value?.phone_2) : "")
const supportEmail = computed(() => contactConfig.value?.email ? getLocaleString(contactConfig.value?.email) : "")
const workingDays = computed(() => contactConfig.value.workingDays)
const workingHours = computed(() => contactConfig.value.workingHours)

</script>

<style lang="scss">
.footer-container {
  @apply flex flex-col text-primary divide-y divide-lightgray bg-white border-t border-gray-200;
}

.footer-content {
  @apply pt-6 sm:pt-12 pb-2.5 px-4;
}

.footer-html-area {
  @apply text-sm py-4 text-primary;
}
</style>